@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  @apply box-border scroll-pt-16;
}

.heading-text {
  @apply text-dark-900/90 dark:text-light-900/90;
}

.primary-text {
  @apply text-dark-900/80 dark:text-light-900/80;
}

.secondary-text {
  @apply text-dark-900/60 dark:text-light-900/60;
}

.amber-text {
  @apply text-amber-500 hover:text-amber-600 dark:text-amber-500/90 dark:hover:text-amber-400/90 transition-all duration-150;
}